import ajax from '@/utils/ajax.js'

// 全国省直辖市列表
 export function listProvince(params) {
  return ajax.post('/api/listProvince',params)
}

// 省包含的市列表
export function listCity(params) {
  return ajax.post('/api/listCity',params)
}

// 市包含的区县列表
export function listDistrict(params) {
  return ajax.post('/api/listDistrict',params)
}


// 申请记录列表
export function listApplication(params) {
  return ajax.post('/api/listApplication',params)
}

// 未提交的申请详情
export function infoApplication(params) {
  return ajax.post('/api/infoApplication',params)
}

// 申请信息暂存
export function tmpSaveApplication(params) {
  return ajax.post('/api/tmpSaveApplication',params)
}

// 获取用户信息-填写申请信息
export function infoUser(params) {
  return ajax.post('/api/infoUser',params)
}
// 身份证实名核验：身份证照片
export function checkIdCardByImg(params){
  return ajax.post('/api/checkIdCardInformation',params)
}

// 身份证实名核验：身份证号+姓名
export function checkIdCardByCode(params){
  return ajax.post('/api/idCardVerification',params)
}

// 获取人脸核身地址和参数
export function getFace(params){
  return ajax.post('/api/getFace',params)
}

// 获取人脸核身地址和参数
export function getFace2(params){
  return ajax.get('/api/getFace2',params)
}

// 获取助梦金展示权限
export function isSupportZMJ(params){
  return ajax.post('/api/businessInfo', params)
}