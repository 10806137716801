<template>
  <div class="confirm-alert">
    <van-dialog
      v-model="show"
      class="confirm-dialog"
      @confirm="confirm"
      @cancel="cancel"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <div class="title">{{title}}</div>
      <div class="confirm-content" v-if="subTitle">
        {{subTitle}}
      </div>
      <div class="dialog-button">
        <div class="button-cancel" @click="cancel">{{cancelText || '取消'}}</div>
        <div class="button-confim" @click="confirm">{{okText || '确定'}}</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "提示"
    },
    subTitle: {
      type: String,
      default: ""
    },
    okText: {
      type: String,
      default: ""
    },
    cancelText: {
      type: String,
      default: ""
    },
  },
  data() {
    return {

    };
  },

  mounted() {
  },
  
  methods: {
    confirm() {
      this.$emit("update:show", false)
      this.$emit("ok")
    },
    cancel() {
      this.$emit("update:show", false)
      this.$emit("cancel")
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm-alert {
  color: #333;
  font-size: 28px;
  .title{
    font-size: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
  }
  .confirm-content{
    font-size: 28px;
    border-top: 1px solid #D9D9D9;
    padding: 40px 30px 30px;
    text-align: center;
    .content-item{
      display: flex;
      margin: 20px 0px;
      .item-label{
        flex: 1;
      }
      .item-value{
        text-align: right;
      }
    }
    .amount{
      color: #333;
      font-weight: 600;
    }
  }
  .dialog-button {
    display: flex;
    align-items: center;
    padding: 30px 40px 40px;
    .button-cancel, .button-confim {
      border: 1px solid #1677ff;
      border-radius: 35px;
      flex: 1;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #1677ff;
    }
    .button-confim {
      background: #1677ff;
      color: #fff;
    }
    .button-cancel + .button-confim {
      margin-left: 30px;
    }
  }
}
</style>